import React from 'react';
import { useSpring, animated, config } from 'react-spring';
import './Chooseus.css'; // Ensure this CSS file is created

const ChooseUs = () => {
  const fade = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });

  return (
    <>
      {/* Meta Tags for SEO */}
      <head>
        <title>Why Choose Enack Learning Platform?</title>
        <meta name="description" content="Join Enack.in to master machine learning, web development, and database management with practical experience from expert faculty in Gurgaon." />
        <meta name="keywords" content="machine learning, web development, MongoDB, PyTorch, Keras, Vue.js, Node.js, online courses, Gurgaon, IIT faculty, Enack" />
        <meta name="author" content="Himanshu Dixit" />
        <meta property="og:title" content="Why Choose Enack Learning Platform?" />
        <meta property="og:description" content="Learn machine learning, web development, and database management with hands-on guidance from experienced faculty, both online and offline, in Gurgaon." />
        <meta property="og:image" content="https://enack.in/assets/logo.png" />
        <meta property="og:url" content="https://enack.in" />
        <meta name="twitter:card" content="summary_large_image" />
      </head>

      {/* Structured Data for SEO */}
      <script type="application/ld+json">
      {`
        {
          "@context": "https://schema.org",
          "@type": "EducationalOrganization",
          "name": "Enack",
          "description": "A comprehensive platform for mastering machine learning, web development, and database management with offline sessions in Gurgaon, led by experienced faculty.",
          "url": "https://enack.in",
          "logo": "https://enack.in/assets/logo.png",
          "sameAs": [
            "https://www.facebook.com/enack",
            "https://twitter.com/enack"
          ],
          "author": {
            "@type": "Person",
            "name": "Himanshu Dixit"
          }
        }
      `}
      </script>

      <animated.section style={fade} className="choose-us-container" aria-labelledby="choose-us-heading">
        <animated.h2 id="choose-us-heading" style={fade} className="choose-us-title">Why Choose Enack Learning Platform? 🌟</animated.h2>
        <animated.p className="choose-us-introduction" style={fade}>
          Enack provides comprehensive courses in machine learning, web development, and database management. Learn both online and offline in Gurgaon with expert faculty who bring real-world experience to the classroom.
        </animated.p>
        <animated.div style={fade} className="choose-us-content">
          {featuresData.map((feature, index) => (
            <ChooseUsContentItem
              key={index}
              title={feature.title}
              description={feature.description}
              logo={feature.logo}
              delay={index * 200} // Staggering effect
            />
          ))}
        </animated.div>
      </animated.section>
    </>
  );
};

const ChooseUsContentItem = ({ title, description, logo, delay }) => {
  const fade = useSpring({ 
    opacity: 1, 
    transform: 'translateY(0)', 
    from: { opacity: 0, transform: 'translateY(20px)' }, 
    config: config.stiff, // Stiff transition for a snappier effect
    delay 
  });

  return (
    <animated.article style={fade} className="choose-us-content-item">
      <animated.img src={logo} alt={`${title} logo`} className="choose-us-logo" />
      <animated.h3 style={fade} className="choose-us-content-title">{title}</animated.h3>
      <animated.p style={fade} className="choose-us-content-description">{description}</animated.p>
    </animated.article>
  );
};

// Sample data for features with logos
const featuresData = [
  {
    title: "Master Machine Learning with Scikit-learn 📚",
    description: "Learn the essentials of machine learning with practical applications and hands-on projects from experienced faculty, using tools like Scikit-learn.",
    logo: "https://upload.wikimedia.org/wikipedia/commons/0/05/Scikit_learn_logo_small.svg"
  },
  {
    title: "Deep Learning with PyTorch 🔥",
    description: "Gain expertise in deep learning, and implement advanced models using PyTorch, under the mentorship of industry-experienced instructors.",
    logo: "https://raw.githubusercontent.com/pytorch/pytorch/main/docs/source/_static/img/pytorch-logo-dark.svg"
  },
  {
    title: "Build Neural Networks with Keras 🌐",
    description: "Learn to design deep learning models using Keras, an easy-to-use library, with guidance from experienced faculty members who provide practical, offline training.",
    logo: "https://keras.io/img/logo.png"
  },
  {
    title: "Web Development with Vue.js 🌈",
    description: "Build dynamic and scalable web applications using Vue.js, guided by experienced professionals with real-world experience in full-stack development.",
    logo: "https://vuejs.org/images/logo.png"
  },
  {
    title: "Server-side Development with Node.js 🟢",
    description: "Learn server-side development with Node.js and JavaScript, mastering scalable applications through expert guidance in both online and offline formats.",
    logo: "https://nodejs.org/static/images/logo.svg"
  },
  {
    title: "Database Management with MongoDB 🍃",
    description: "Understand how to work with NoSQL databases like MongoDB, and gain hands-on experience building modern applications under the mentorship of qualified instructors.",
    logo: "https://www.vectorlogo.zone/logos/mongodb/mongodb-icon.svg"
  },
];

export default ChooseUs;

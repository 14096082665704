import React from 'react';
import { FaFacebook, FaInstagram, FaTwitter, FaLinkedin, FaYoutube, FaGithub } from 'react-icons/fa';
import './Footer.css';

const Footer = () => {
  const currentYear = new Date().getFullYear();  // Get the current year dynamically

  return (
    <footer className="footer">
      <div className="footer-container">
        {/* About Us Section */}
        <section className="footer-column">
          <h3>About Us</h3>
          <p>
            Enack Platform is your go-to spot for world-class education and training. We're on a mission to
            turn learning into an adventure. Join us as we tackle the future with the power of AI and a splash
            of creativity!
          </p>
          <p>Curious about AI? Just ask Siri or Alexa—we're right there with them!</p>
        </section>

        {/* Contact Us Section */}
        <section className="footer-column">
          <h3>Contact Us</h3>
          <p>
            Have questions? Shoot us an email at{' '}
            <a href="mailto:info@enack.in" aria-label="Email Enack Platform">
              info@enack.in
            </a>
          </p>
          <p>
            Prefer a chat? Call us at{' '}
            <a href="tel:+919266369462" aria-label="Call Enack Platform">
              +91 9266369462
            </a>
          </p>
        </section>

        {/* Follow Us Section */}
        <section className="footer-column">
          <h3>Follow Us</h3>
          <div className="social-icons">
            <a href="https://www.facebook.com" aria-label="Facebook" target="_blank" rel="noopener noreferrer">
              <FaFacebook />
            </a>
            <a href="https://www.instagram.com" aria-label="Instagram" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="https://www.twitter.com" aria-label="Twitter" target="_blank" rel="noopener noreferrer">
              <FaTwitter />
            </a>
            <a href="https://www.linkedin.com/company/enacklabs/" aria-label="LinkedIn" target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </a>
            <a href="https://www.youtube.com" aria-label="YouTube" target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </a>
            <a href="https://github.com" aria-label="GitHub" target="_blank" rel="noopener noreferrer">
              <FaGithub />
            </a>
          </div>
          <p>We don’t have a robot army yet, but we’re working on it. Follow us for updates!</p>
        </section>

        {/* Quick Links Section */}
        <section className="footer-column">
          <h3>Quick Links</h3>
          <ul className="quick-links">
            <li><a href="/courses">Our Courses</a></li>
            <li><a href="/about">About Us</a></li>
            <li><a href="/contact">Contact Us</a></li>
            <li><a href="/blog">Blog</a></li>
            <li><a href="/faq">FAQ</a></li>
          </ul>
        </section>

        {/* AI & Innovations Section */}
        <section className="footer-column">
          <h3>AI & Innovations</h3>
          <p>
            Shoutout to our AI buddies: Google AI, OpenAI, and IBM Watson. They're the real MVPs!
          </p>
          <p>Our AI isn't quite Skynet, but it’s working on being friendly and helpful.</p>
        </section>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>&copy; {currentYear} Enack Platform. All rights reserved.</p>
        <p className="footer-love">Made with ❤️ and a touch of AI magic in India</p>
      </div>
    </footer>
  );
};

export default Footer;

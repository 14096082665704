import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';
import './HeroSection.css';
import CourseSelection from './CourseSelection'; 

const HeroSection = () => {
  const [showCourseSelection, setShowCourseSelection] = useState(false);

  // Animation setup for fading in the hero section
  const fade = useSpring({ opacity: 1, from: { opacity: 0 }, delay: 200 });

  // Define the URLs for different courses
  const courseFormURLs = {
    'Machine Learning': 'https://docs.google.com/forms/d/1xCyPwDSGKira7VuBb3-Vjg0_vRY1V2PNXB0X6USNyVs/viewform',
    'Web Development': 'https://docs.google.com/forms/d/e/1FAIpQLSdF8j3TXncX_WUS90iUEtpT1b4HU7sm04Rjp6G9vX3XhZl4lQ/viewform',
    'Business Analytics': 'https://docs.google.com/forms/d/e/1FAIpQLSdJ3VoGgUL47vSwdIdAQoadp6nbouIPFWMwIhMsZO6GMI-CWw/viewform',
  };

  const handleCourseSelection = (course) => {
    const formURL = courseFormURLs[course];
    if (formURL) {
      window.open(formURL, '_blank');
    } else {
      console.error('No URL found for the selected course:', course);
    }
  };

  return (
    <section className="hero-section">
      <animated.div style={fade}>
        <header className="hero-header">
          <h1>Unlock the Power of AI for Education</h1>
         
        </header>
        
        <div className="hero-content">
          {!showCourseSelection ? (
            <>
              <button
                className="cta-button"
                onClick={() => setShowCourseSelection(true)}
                aria-label="Explore available courses to enhance your learning"
              >
                Explore Courses
              </button>
              <p className="intro-text">
                Choose a course to see detailed information and start your journey into the world of AI-driven learning.
              </p>
            </>
          ) : (
            <CourseSelection onSelectCourse={handleCourseSelection} />
          )}
        </div>
      </animated.div>
    </section>
  );
};

export default HeroSection;
